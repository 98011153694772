// LANDING
.platform_landing_main {
  display: grid;
  height: 100%;

  .input-primary {
    height: 40px;
  }

  &__content {
    display: grid;
    grid-template-columns: 50% 50%;

    &_info {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_background {
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
        max-width: 850px;
        height: auto;
      }
    }

    &_form {
      display: flex;
      justify-content: center;
      background-color: #fff;
      text-align: center;
      vertical-align: top;
      height: 100%;
      align-items: center;
      position: relative;
      overflow: auto;
    }
  }

  &__footer {
    background-color: #221C33;
    color: #6C6685;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;

    &_links {
      display: flex;
      gap: 5px;
      align-items: center;

      > a {
        color: inherit;
        text-decoration: none;
      }
    }

    &_active {
      grid-template-rows: calc(100% - 50px) 50px;
    }
  }
}

.platform_landing {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__info {
    display: grid;

    b {
      padding: 0 15px;
      text-align: center
    }

    &__header {
      display: grid;
      margin-bottom: 20px;
      text-align: center;

      b {
        &:nth-child(1) {
          font-size: 30px;
        }

        &:nth-child(2) {
          font-size: 20px;
          color: #FD7859;
        }
      }
    }

    &__content {
      display: grid;

      ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      b {
        font-size: 18px;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    font-size: 26px;
    max-width: 900px;
    padding: 0 20px;
    margin-bottom: 20px;

    span {
      &:nth-child(2), &:nth-child(3) {
        font-weight: 300;
        font-size: 16px;
        margin-top: 0;
      }
    }

    svg, img {
      height: 75px;
      width: 75px;
      margin: 0 auto;
    }
  }

  &__content {
    display: grid;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    gap: 15px;
  }

  &__verify {
    display: grid;
    justify-content: center;
    align-items: center;

    span {
      font-size: 18px;
    }

    img {
      width: 200px;
      margin: 0 auto;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;

    a {
      color: #83bceb;
      text-decoration: none;
      margin-left: .3em;
      cursor: pointer;
    }

    &_signin {
      justify-content: center;
      text-align: left;
      gap: 12px;
    }
  }

  .platform_select_simple {
    label {
      color: rgba(0, 0, 0, 0.54);
      transform: scale(1);
      height: 15px;
    }
  }
}

@media (max-width: 1250px) {
  .platform_landing_main__content {
    grid-template-columns: 100%;

    &_info, &_background {
      display: none;
    }
  }
}

@media (max-width: 960px) {
  .platform_landing_main__footer {
    display: grid;
    padding: 18px;
    line-height: 4px;
    font-size: 12px;
  }
}

@media (max-width: 750px) {
  .platform_landing, .platform_landing__info, div#menu- .MuiPaper-root {
    transform: scale(0.8) !important;;

    .MuiListItem-button {
      min-height: 35px;
    }
  }
}

@media (max-width: 370px) {
  .platform_landing_main__footer {
    padding: 8px;
    line-height: normal;
    display: flex;
  }
}